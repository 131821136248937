<template>
  <div>
    <div class="mass-action-settings">
      <el-form
        size="small"
        ref="editForm"
        label-position="top"
      >


        <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_name')" prop="name">
          <el-input
            style="width: 600px"
            maxlength="255"
            show-word-limit
            v-model="actionData.name"></el-input>
        </el-form-item>
        <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_desc')" prop="desc">
          <el-input
            style="width: 600px"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 6}"
            v-model="actionData.desc"></el-input>
        </el-form-item>

        <el-form-item label="Шкала" prop="parent_id">
          <el-select
            v-model="actionData.scale.parent_id"
            style="width: 600px"
          >
            <el-option
              v-for="template in scaleTemplates"
              :key="template.id"
              :label="template.name"
              :value="template.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Единица измерения" prop="unit_id">
          <el-select
            v-model="actionData.scale.unit_id"
            style="width: 600px"
            filterable
          >
            <el-option
              v-for="unit in scalesUnits"
              :key="unit.id"
              :label="unit.symbol"
              :value="unit.id"
            ></el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="Вес КПЭ(план)" prop="weight_plan">
          <el-input
            v-model="actionData.scale.weight_plan"
            v-number-format="{decimals: 2}"
            style="width: 600px"
          ></el-input>
        </el-form-item>

        <el-form-item label="Целевой уровень выполнения" prop="target_level">
          <el-input
            v-model="actionData.scale.target_level"
            v-number-format="{decimals: 6}"
            style="width: 600px"
          ></el-input>
        </el-form-item>

      </el-form>


      <el-popconfirm
        style=""
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите добавить КПЭ?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Добавить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
      :error-props="['id', 'info.last_name', 'info.first_name', 'info.middle_name', 'info.company']"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import requestSender from "@/api/base/requestSender";

export default {
  name: "card-add-element-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageNegotiationSelect, MassActionResult, CardsStageSelect},

  computed: {
    actionButtonDisabled() {
      return false;
    }
  },

  mounted() {

    requestSender('get', 'scale/templates-list', {})
      .then(data => {
        this.scaleTemplates = data.scales;
      })

    this.$api.library.getScalesUnits()
      .then(data => {
        this.scalesUnits = data.scales_units;
      })
  },
  data() {
    return {
      scaleTemplates: [],
      scalesUnits: [],
      actionData: {
        name: null,
        desc: null,
        scale_type: 'general',
        scale: {
          parent_id: null,
          unit_id: null,
          weight_plan: null,
          target_level: null,
        },
      }
    }
  },
  methods: {},
}
</script>
